@use './themes' as *;
@use 'sass:map';
@use '../../og-lib/src/lib/theme/styles/index' as *;
@use '@nebular/theme/styles/globals' as *;
@use '@nebular/auth/styles/globals' as *;
@use '../../og-lib/src/lib/theme/styles/variables' as *;

@include nb-install() {
  @include nb-theme-global();
  @include nb-auth-global();
}

/* You can add global styles to this file, and also import other style files */
.kebab-menu ul.menu-items {
  text-align: left !important;
}
router-outlet.main-router + * {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
// common scss

// input
.loginbtn {
  margin: 20px 0;
  button.appearance-filled.status-basic {
    color: #fff !important ;
    background: #f4001f !important;
    text-transform: inherit;
    &:hover {
      color: rgb(41, 40, 40);
      border: 1px solid #8f8f8f;
    }
  }
  button.appearance-filled.status-basic.btn-disabled {
    color: #000 !important ;
    background: #edf1f7 !important;
    text-transform: inherit;
    &:hover {
      color: rgb(41, 40, 40);
      border: 1px solid #8f8f8f;
    }
  }
}

.input {
  input {
    width: 92%;
    margin: 10px 0;
    max-width: inherit !important;
    border-radius: 0;
    background: #fff;
  }
}
.input-title {
  margin-top: 10px;
  span {
    font-size: 13px;
  }
}
.google-btn {
  width: 24.125rem;
  height: 4rem;
  border-radius: 0.375rem;
  font-size: 1.25rem;
  font-family: #{nb-theme(font-family-primary)};
  font-weight: 600;
  line-height: 1.5rem;
  background-color: map.get($font, 'light');
  border: 0.063rem solid map.get($color, 'border-disable');
  position: absolute;

  &:hover {
    border-color: map-get($font, 'placeholder');
  }

  &:active {
    border-color: map-get($font, 'primary');
  }
}

.sign-up-button {
  button {
    font-weight: normal;
    border: 1px solid #d9d9d9;
    background: #fff;
    text-transform: inherit;
    font-size: 13px;
    &:hover {
      border: 1px solid #8f8f8f;
    }
    img {
      margin-right: 5px;
    }
  }
}

.footer {
  color: #525252;
  a {
    text-decoration: none;
    color: #19a5ff;
    font-weight: 700;
  }
}

h1,
.h1 {
  font-size: 2.25rem !important;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.125rem;
}

.border {
  border: none !important;
}

.devider {
  color: #525252;
  font-size: 13px;
  margin: 0 10px 20px;
  &:before {
    content: '';
    height: 1px;
    background: #d9d9d9;
    width: 110px;
    margin-right: 10px;
  }

  &:after {
    content: '';
    height: 1px;
    background: #d9d9d9;
    width: 110px;
    margin-left: 10px;
  }
}

.card-row {
  display: flex;
  margin: 0 -0.5rem;
}

.card-col {
  flex: 1 0 calc(50% - 1rem);
  margin: 0 0.5rem;
}
.card-row {
  margin: 0;
  padding: 30px 70px;
  .sign-in-title {
    h2 {
      font-size: 36px;
      margin: 50px 0 10px 0;
    }
    h3 {
      font-size: 24px;
      color: #525252;
      margin: 0;
    }
    p {
      font-size: 16px;
      margin: 0 0 30px 0;
    }
  }
}

.route-tabset .route-tab a.tab-link:before {
  width: var(--route-tabset-tab-underline-width-cover) !important;
  left: var(--route-tabset-tab-underline-left-position) !important;
  bottom: var(--route-tabset-tab-underline-bottom-position) !important;
}

.route-tabset + router-outlet + * {
  height: calc(100% - 3.25rem);
  display: flex;
  flex-direction: column;
  & > * {
    flex-grow: 1;
  }
}

input[nbinput][readonly],
nb-select[readonly] > button {
  background-color: var(
    --input-basic-hover-background-color-disabled
  ) !important;
  border-color: var(--input-basic-hover-border-color-disabled) !important;
  color: var(--input-basic-text-color-disabled) !important;
}

.cdk-overlay-container {
  z-index: 1041 !important; // is not provided through a variable
}

nb-sidebar,
nb-sidebar > div.main-container {
  transition: width 0.3s;
}

input[disabled],
select[disabled] {
  background-color: #{nb-theme(color-primary-disabled)};
}
// bootstrap css messing with nb toggle scss for labels
label.toggle-label {
  margin: 0 !important;
}

fieldset {
  border: var(--card-border-width) var(--card-border-style)
    var(--card-border-color);
  margin-bottom: var(--card-margin-bottom);
  border-radius: var(--card-border-radius);
}
nb-sidebar.compacted nb-menu .menu-item a.active::before {
  top: 0.563rem;
  height: 0.9rem;
  width: 0.063rem;
}

nb-sidebar.compacted {
  width: 4rem !important;
}

nb-sidebar.compacted nb-menu .menu-items > .menu-item a {
  justify-content: left !important;
}

nb-toast.status-success {
  background: #e0fde2;
  border-color: #e0fde2;
  color: #047205;
  border-left: 0.188rem solid #047205 !important;
}

nb-toast.status-success:hover {
  background-color: #e0fde2 !important ;
  border: 0.188rem 0px 0px 0px #047205 !important;
}
nb-toast .icon-container {
  border-radius: 1rem;
  min-width: 1.25rem;
  min-height: 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

nb-toast .message {
  font-weight: 600;
  display: inline-flex;
}
nb-toast.status-success .icon-container {
  background: #047205;
  color: #e0fde2;
}

.add-actual-resource-popup {
  width: 100%;
  height: 100%;
  nb-dialog-container {
    width: inherit;
    height: inherit;
    nb-card {
      height: 100%;
      border-radius: 0;
    }
  }
}

.parent-class {
  // border-bottom: 1px solid #d9d9d9;
  .inner-div {
    height: fit-content;
  }
  .layout {
    background: #fff;
  }
}
